// Navbar.jsx
import React from 'react';
import './Navbar.css'; // Import custom styles
import logo from '../assests/Group 231.png'
import mapPin from '../assests/Map pin.png'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-item">
        <span>6TH AND 7TH DECEMBER</span>
      </div>
      
      <div className="navbar-logo">
      <img src={logo} alt="The Hills Festival Logo" className="logo-image" />
      </div>
      
      <div className="navbar-item-location">
      <img src={mapPin} alt="Map Pin" className="map-pin" />
      <a
          href="https://www.google.com/maps?q=Lum+'er+Pyngngad"
          target="_blank"
          rel="noopener noreferrer"
          className="location-link"
        >
          Lum 'Er Pyngngad
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
