// App.js
import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import EventIntroSection from './pages/EventIntroSection';
import HeroSection from './pages/HeroSection';
import Lineup from './pages/lineup';
import Contact from './pages/contact';
import TicketsPage from './pages/TicketsPage';
import GalleryPage from './pages/GalleryPage';
import ExperiencesPage from './pages/ExperiencesPage';
import TermsAndConditions from './pages/terms-and-conditions';
import Accommodation from './pages/Accommodation';

function App() {
  // Create refs for the sections
  const ticketsRef = useRef(null);
  const galleryRef = useRef(null);
  const experiencesRef = useRef(null);
  const accommodationRef = useRef(null);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <HeroSection />
        <Lineup />
        <EventIntroSection
          ticketsRef={ticketsRef}
          galleryRef={galleryRef}
          experiencesRef={experiencesRef}
          accommodationRef={accommodationRef}
        />

        {/* Render sections with refs for smooth scrolling */}
        <section ref={experiencesRef}>
          <ExperiencesPage />
        </section>
        <section ref={galleryRef}>
          <GalleryPage />
        </section>
        <section ref={ticketsRef}>
          <TicketsPage />
        </section>
        <section ref={accommodationRef}>
          <Accommodation />
        </section>
        
        <Contact />
        <TermsAndConditions />
      
      </div>
    </Router>
  );
}

export default App;
