// HeroSection.jsx
import React, { useEffect, useState } from 'react';
import './Herosection.css';

// Import the border images and video
import backgroundVideo from '../assests/Intro.mp4';

const HeroSection = () => {
  const targetDate = new Date('2024-12-06T00:00:00'); // Set your target date here

  const calculateTimeLeft = () => {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Clear interval on component unmount
  }, []);

  return (
    <div className="hero-section">
      <video autoPlay loop muted playsInline preload="auto" className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="overlay">
      <h2 className="edition-title">
          Get Ready For The<br />
          <span className="highlighted">5th Edition</span>
        </h2>
        <div className="timer">
          <div className="time-box">
            <div className="number">{timeLeft.days || '00'}</div>
            <div className="label">days</div>
          </div>
          <span className="colon">:</span>
          <div className="time-box">
            <div className="number">{timeLeft.hours || '00'}</div>
            <div className="label">hours</div>
          </div>
          <span className="colon">:</span>
          <div className="time-box">
            <div className="number">{timeLeft.minutes || '00'}</div>
            <div className="label">minutes</div>
          </div>
          <span className="colon">:</span>
          <div className="time-box">
            <div className="number">{timeLeft.seconds || '00'}</div>
            <div className="label">seconds</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
