import React from 'react';
import '../pages/ticket.css';
import scenicImage from '../assests/umiam.png'; // Scenic background image path
import radialLogo from '../assests/Layer_1 (5).png'; // Radial logo image path
import vipTicket from '../assests/Untitled design (13).png'; // VIP ticket image path
import festivalPass from '../assests/Untitled design (14).png'; // Festival Pass image path
import day1Pass from '../assests/Untitled design (16).png'; // Day 1 Pass image path
import day2Pass from '../assests/Untitled design (15).png'; // Day 2 Pass image path

const TicketPage = () => {
  return (
    <div className="ticket-page">
      {/* Enclosing Rectangle for the Header */}
      <div className="header-container-ticket">
        <img src={radialLogo} alt="Radial Logo" className="header-logo" />
        <h1 className="header-title-ticket">TICKETS</h1>
      </div>

      {/* Placeholder for Scenic Image */}
      <div className="scenic-image-container">
        <img src={scenicImage} alt="Scenic View" className="scenic-image" />
      </div>

      {/* Additional Content Below */}
      <section className="description-section-ticket">
        <h2>SHOWCASING THE BEST</h2>
        <h3>ONLY, THE VERY BEST</h3>
        <p>
          Established in 2018, The Hills Festival showcases Meghalaya's vibrant
          culture over two days, blending modernity and tradition. Set against
          the stunning Umiam Lake, it features global talents and promotes
          eco-consciousness. Join us in 2024 for a memorable celebration of
          music, art, and local heritage.
        </p>
      </section>

      <div className="ticket-options">
        <img src={vipTicket} alt="VIP Admission Pass" className="ticket-image" />
        <img src={festivalPass} alt="Festival Pass" className="ticket-image" />
        <img src={day1Pass} alt="Day 1 Pass" className="ticket-image" />
        <img src={day2Pass} alt="Day 2 Pass" className="ticket-image" />
      </div>
    </div>
  );
};

export default TicketPage;