import React from 'react';
import '../pages/contact.css';
import meghalayaTourism from '../assests/meghLogo.png';
import bookMyShow from '../assests/bookmyshow-logo-vector.png';
import { FaInstagram, FaFacebook,FaYoutube } from 'react-icons/fa';
import { BsTwitter } from "react-icons/bs";

const Contact = () => {
  return (
    <div className="contact-page">
      <h2 className="partners-title">PARTNERS</h2>
      
      <div className="partners-section">
        <img src={meghalayaTourism} alt="Meghalaya Tourism" className="partner-logo" />
        <img src={bookMyShow} alt="Book My Show" className="partner-logo" />
      </div>

      <h2 className="contact-title">CONTACT US</h2>
      
      <div className="queries-section">
        <div className="query">
          <h3>MANAGEMENT QUERIES</h3>
          <p>MANAGEMENT</p>
          <p>@THEHILLSFESTIVAL.IN</p>
        </div>
        
        <div className="query">
          <h3>ARTIST QUERIES</h3>
          <p>ARTIST</p>
          <p>@THEHILLSFESTIVAL.IN</p>
        </div>
        
        <div className="query">
          <h3>HOSPITALITY QUERIES</h3>
          <p>HOSPITALITY</p>
          <p>@THEHILLSFESTIVAL.IN</p>
        </div>
      </div>
      
      <div className="social-media-section">
        <p>FIND US AT</p>
        <div className="social-icons">
        <a href="https://www.instagram.com/thehillsfestivalmeghalaya" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.facebook.com/thehillsfestivalmeghalaya" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.youtube.com/@thehillsfestival" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
          <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fthehillsfest" target="_blank" rel="noopener noreferrer">
            <BsTwitter />
          </a>
    
        </div>
      </div>
    </div>
  );
};

export default Contact;
