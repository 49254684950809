import React, { useRef } from 'react';
import './Accommodation.css';
import campImage from '../assests/camp1.png'
import roinamImage from '../assests/roinam-retreat-umbir (1).jpg';
import rikynjaiImage from '../assests/ri kynjai.jpg';
import iamonlangImage from '../assests/Iamonlang.jpg';
import lanicholasImage from '../assests/La nicholas.jpg';
import dalevillaImage from '../assests/dale-villa-ucc-burnihat-guest-house-zr5kx7qk8e.jpg';
import mapleImage from '../assests/the-maple-burnihat-bed-and-breakfast-ymybs6ji71.jpg';
import cafeUmbirImage from '../assests/cafe umbir.jpg';
import elasahImage from '../assests/elasah-guest-house-ucc-burnihat-home-stay-6hhyas14mb.jpg';
import odysseyImage from '../assests/odyssey.jpg';
import sawanbiangImage from '../assests/Sawanbiang Guest House by OK North East 15.jpg';
import nohsngithiangImage from '../assests/nong.jpg';
import lakeview from '../assests/hotel1.jpg';

const accommodations = [

  {
    name: "CampX® Camping Booking",
    address: "Lum 'Er Pyngngad, Meghalaya, India",
    contact: "+91 9387867966",
    image: campImage
  },
 
  {
    name: "Roinam Resort",
    address: "Umbir, Near Umiam Lake, Meghalaya, India",
    contact: "+91 7005071700",
    image: roinamImage
  },
 
  {
    name: "Iamonlang Resort & Restaurant",
    address: "Lumsohpetbneng Lawnongthroh, Meghalaya, India",
    contact: "+91 7627933855",
    image: iamonlangImage
  },

  {
    name: "Emidahun Lakeview Home stay & Restaurant",
    address: "GS Road, Shillong, Meghalaya, India",
    contact: "+91 9366848955",
    image: lakeview
  },


  {
    name: "La Nicholas - Lake View",
    address: "UMBIR-MAIRANG ROAD, Meghalaya, India",
    contact: "+91 8414006293",
    image: lanicholasImage
  },
  
  {
    name: "The Maple",
    address: "Umsaw Khwan Ucc Road, Umiam, Meghalaya, India",
    contact: "+91 9863817403",
    image: mapleImage
  },
  {
    name: "Cafe Umbir Resort",
    address: "Assisi School, Umbir-Mairang Rd, Meghalaya, India",
    contact: "+91 9089969555",
    image: cafeUmbirImage
  },
  {
    name: "Elasah Guest House",
    address: "Umiam, Meghalaya, India",
    contact: "+91 8787338381",
    image: elasahImage
  },
  {
    name: "Odyssey Stays (B&B)",
    address: "Umbir, Umiam Lake, Meghalaya, India",
    contact: "+91 8208698087",
    image: odysseyImage
  },
  {
    name: "Sawanbiang Guest House",
    address: "Umbir, Meghalaya, India",
    contact: "+91 7578013817",
    image: sawanbiangImage
  },
  {
    name: "Nohsngithiang",
    address: "Umbir, Meghalaya, India",
    contact: "+91 9233272932",
    image: nohsngithiangImage
  },

  {
    name: "The Dale Villa",
    address: "UCC Road, Umiam, Meghalaya, India",
    contact: "+91 8638675779",
    image: dalevillaImage
  },

  {
    name: "Ri Kynjai – Serenity by the Lake",
    address: "Umiam Lake, Meghalaya, India",
    contact: "+91 91234 56789",
    image: rikynjaiImage
  },
];

const Accommodation = () => {
  const listRef = useRef(null);

  const scrollLeft = () => {
    listRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    listRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  return (
    <div className="accommodation-container">
      <h2>Welcome to Your Stay At The Hills Festival</h2>
      <div className="scroll-buttons">
        <button onClick={scrollLeft} className="scroll-button left">◀</button>
        <div ref={listRef} className="accommodation-list">
          {accommodations.map((place, index) => (
            <div key={index} className="accommodation-card">
              <img src={place.image} alt={`${place.name}`} className="accommodation-image" />
              <h3>{place.name}</h3>
              <p><strong>Address:</strong> {place.address}</p>
              <p><strong>Contact:</strong> {place.contact}</p>
            </div>
          ))}
        </div>
        <button onClick={scrollRight} className="scroll-button right">▶</button>
      </div>
    </div>
  );
};

export default Accommodation;
