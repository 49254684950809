// components/ExperiencesPage.js
import React from 'react';
import ExperienceCard from '../components/ExperienceCard';
import '../pages/ExperiencesPage.css';

// Import card images
import cuisineImage from '../assests/Untitled design (35).png';
import musicImage from '../assests/Untitled design (25).png';
import natureImage from '../assests/Untitled design (33).png';
import tribalImage from '../assests/Untitled design (31).png';

// Import overlay images
import cuisineOverlay from '../assests/Layer_1 (1).png';
import musicOverlay from '../assests/Layer_1 (2).png';
import natureOverlay from '../assests/FINAL_nature_glyph_no_background.png';
import tribalOverlay from '../assests/Layer_1 (4).png';

const ExperiencesPage = () => {
  const experiences = [
    {
      title: 'Cuisine',
      description: [
        "We offer unique experiences as part of our festival IP, which is dedicated to elevating Meghalaya's regional food and wild edibles through HILLSFEAST and The Forage Aisle.",
        "",
        "HILLSFEAST: A collaborative initiative with food entrepreneurs, highlighting Meghalaya's vibrant flavors and culinary entrepreneurship.",
        "The FORAGE AISLE: Provides an immersive journey that elevates regional food and wild edibles, featuring chefs who showcase unique ingredients and celebrate traditional culinary practices."
      ],
      image: cuisineImage,
      titleBgColor: '#f3e86b',
      overlayContent: cuisineOverlay,
    },
    {
      title: 'Music',
      description: 'This year, we’ll bring an even richer blend of local tradition and fresh sounds, showcasing a diverse mix of genres and styles. Audiences can immerse themselves in the vibrant rhythms of traditional music, discover emerging artists, and experience the unique talents of local artisans, making for an unforgettable cultural journey.',
      image: musicImage,
      titleBgColor: '#5fb8c9',
      overlayContent: musicOverlay, // Overlay image for Music
    },
    {
      title: 'Campsite & Activities',
      description: [
        'Experience the magic of The Hills Festival with CampX, our official partner for this year’s edition, nestled in The Best View and surrounded by serene natural beauty under the trees. After the excitement of live music, relax with acoustic sessions that create the perfect atmosphere to unwind.',
        '',
        'ACTIVITIES:',
        'Enjoy thrilling experiences like hot air ballooning, sky walking, and many more games and activities designed to make your festival experience truly unforgettable.'
      ],
      image: natureImage,
      titleBgColor: '#7ec85f',
      overlayContent: natureOverlay, // Overlay image for Nature
    },
    {
      title: 'Community',
      description: 'Over the years, The Hills Festival has become a close-knit community, blending shared experiences, creativity, and respect for local traditions with a modern twist. Partnering with Umbir Village, we’ve promoted it as a tourism destination while supporting its growth. By showcasing the village’s beauty and local talent, we’ve created opportunities for artisans, food entrepreneurs, and performers, boosting Umbir’s presence in the tourism scene and fostering sustainable growth and collaboration.',
      image: tribalImage,
      titleBgColor: '#c63f0e',
      overlayContent: tribalOverlay, // Overlay image for Tribal
    },
  ];

  return (
    <div className="experiences-page">
      <div className="Vector1"></div>

      <div className="header-container">
        <div className="Vector2"></div>
          <div className="header-title-box">
            <h2 className="title">EXPERIENCES</h2>
          </div>
          <p className="subtitle">
            Immerse yourself in Meghalaya’s beauty with lakeside escapes, live music, local flavors, art, and eco-friendly camping under the stars— Craft your unique Hills Festival experience.
          </p>
        
        <div className="Vector10"></div>
        <div className="Vector11"></div>
      </div>

      <div className="experiences-container">
        {experiences.map((experience, index) => (
          <ExperienceCard key={index} {...experience} />
        ))}
      </div>
    </div>
  );
};

export default ExperiencesPage;
