// EventIntroSection.jsx
import React from 'react';
import './EventIntroSection.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';


const EventIntroSection = ({ ticketsRef, galleryRef, experiencesRef, accommodationRef }) => {
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <div className="event-intro-section">
      {/* Description */}
      <div className="header">
        <p>Join us in embracing tradition, modernity, and eco-consciousness with over 20+ global artists
        set against the breathtaking beauty of Umiam lake</p>
      </div>

      {/* Ticker */}
      <div className="ticker">
  <div className="ticker-content">
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>

    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
    <a href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true" 
       className="ticker-link" 
       target="_blank" 
       rel="noopener noreferrer">
      get tickets
    </a>
    <span>•</span>
  </div>
</div>


      {/* Navigation Links */}
      <div className="links">
        <button className="link-item" onClick={() => scrollToSection(ticketsRef)}>
          <i className="fas fa-ticket-alt"></i> TICKETS
        </button>
        <button className="link-item" onClick={() => scrollToSection(galleryRef)}>
          <i className="fas fa-photo-video"></i> GALLERY
        </button>
        <button className="link-item" onClick={() => scrollToSection(experiencesRef)}>
          <i className="fas fa-map-marked-alt"></i> EXPERIENCES
        </button>
        <button className="link-item" onClick={() => scrollToSection(accommodationRef)}>
    <i className="fas fa-bed"></i> ACCOMMODATION
  </button>
      </div>
    </div>
  );
};

export default EventIntroSection;
