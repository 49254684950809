import React, { useState } from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>

      <div className="section">
        <h2 onClick={() => toggleSection('general')}>General Rules</h2>
        {activeSection === 'general' && (
          <ul>
            <li>Prohibited Items: Weapons, dangerous items, drones, and fireworks are not allowed on festival grounds.</li>
            <li>No Outside Food or Beverages: Please support the local vendors on site by purchasing food and beverages from the festival stalls. Outside food and drinks are not allowed within the festival grounds.</li>
            <li>No Outside Alcohol: Alcohol may only be purchased and consumed at designated bars inside the festival grounds. Outside alcohol is not permitted.</li>
            <li>Wristbands: Do not remove or tamper with your wristband. Wristbands must be worn at all times during the festival.</li>
            <li>ID and Entry Requirements: Bring a valid photo ID to collect wristbands at the festival box office. Wristbands are required for re-entry.</li>
            <li>Pets: Pets are welcome but must be kept on a leash throughout the festival grounds. Don’t forget to clean up after them to keep the space enjoyable for everyone.</li>
            <li>Camping Rules: Please set up camp only in designated camping zones. Unauthorized camping is not permitted to ensure everyone’s safety.</li>
          </ul>
        )}
      </div>

      <div className="section">
        <h2 onClick={() => toggleSection('safety')}>Safety & Security</h2>
        {activeSection === 'safety' && (
          <ul>
            <li>Drugs and Illegal Substances: Possession or use of drugs is strictly prohibited and will result in removal from the festival site.</li>
            <li>Security Checks: All guests may be subject to security checks upon entry. Cooperation with security is required.</li>
            <li>Security Personnel: Festival staff and security personnel are spread out across the venue. Don’t hesitate to approach them if you need help or in case of an emergency.</li>
            <li>Emergency Preparedness: In case of severe weather, please follow the guidance of festival staff to stay safe. Pack layers and rain gear to be prepared for any conditions.</li>
            <li>Responsible Drinking: Drink responsibly. Don’t let excessive drinking ruin the experience for fellow festival attendees.</li>
            <li>Smoking Rules: Smoking is permitted only in designated smoking areas.</li>
            <li>Noise Control: Please keep noise levels down during designated quiet hours and in camping areas to preserve the natural surroundings and ensure a peaceful experience for all festival attendees.</li>
            <li>Lost and Found: Lost something? Visit our designated Lost and Found area on-site. While we will do our best to reunite you with your belongings, THF is not liable for any lost or stolen items.</li>
          </ul>
        )}
      </div>

      <div className="section">
        <h2 onClick={() => toggleSection('community')}>Community & Cultural Engagement</h2>
        {activeSection === 'community' && (
          <ul>
            <li>Community Involvement: Please respect local traditions and the environment. Be sure to engage positively with the local culture and people.</li>
            <li>Support the Local Economy: Purchase handmade crafts, local food, and merchandise from our local vendor stalls to directly support the region’s artisans and small businesses.</li>
            <li>Be Considerate of Others: The festival is a shared space. Please be mindful of your fellow attendees by keeping pathways clear, not blocking views during performances, and being respectful in shared spaces like restrooms and dining areas.</li>
          </ul>
        )}
      </div>

      <div className="section">
        <h2 onClick={() => toggleSection('sustainability')}>Sustainability & Environmental Responsibility</h2>
        {activeSection === 'sustainability' && (
          <ul>
            <li>Waste Management: Help us maintain the beauty of our surroundings by disposing of trash properly. Use the bins provided for sorting recyclables and other waste.</li>
            <li>Zero-Waste Initiative: We encourage all attendees to reduce waste, reuse where possible, and recycle whenever you can. Let’s work together to minimize our environmental footprint and keep the festival green.</li>
          </ul>
        )}
      </div>
      <p className="footer">2024 HYPE &copy;. All rights reserved.</p>
    </div>
  );
};

export default TermsAndConditions;
