// components/ExperienceCard.js
import React from 'react';

const ExperienceCard = ({ title, description, image, borderColor, titleBgColor, overlayContent }) => {
  return (
    <div className="experience-card">
      <div className="image-container" style={{ borderColor: borderColor }}>
        <img src={image} alt={`${title}`} className="experience-image" />
        <div className="icon-overlay">
          <img src={overlayContent} alt={`${title} overlay`} className="overlay-image" />
        </div>
      </div>
      <h3 className="experience-title" style={{ backgroundColor: titleBgColor }}>{title}</h3>
      <div className="experience-description">
          {Array.isArray(description)
            ? description.map((line, index) => (
                <p key={index}>{line || <br />}</p>
              ))
            : <p>{description}</p>}
        </div>
    </div>
  );
};

export default ExperienceCard;
